import defaults from "~/public/pwa-defaults/api-defaults.json";

interface UseListingParams {
  listingId?: string | null;
  brandSlug?: string | null;
  listingType?: string;
  debug?: any;
  listingRoute?: any;
}

interface GetListingParams {
  prop?: any;
  order?: any;
  page?: any;
  merge?: boolean;
  query?: any;
  debug?: any;
  routeChange?: boolean;
  prefetch?: boolean;
  isSearch?: boolean;
  filter?: boolean;
}

export function useListing({
  listingId = null,
  brandSlug = null,
  listingType = "categoryListing",
  debug = null,
  listingRoute = null,
}: UseListingParams) {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const route = listingRoute ? listingRoute : app._route;

  const router = useRouter();

  let categoryId: string | null = null;

  if (listingId) {
    categoryId = listingId;
  } else if (
    listingType === "categoryListing" &&
    store &&
    store.getCurrentCategory &&
    store.getCurrentCategory.id
  ) {
    categoryId = store.getCurrentCategory.id;
  } else if (
    listingType === "categoryListing" &&
    store &&
    store.getCurrentListing &&
    store.getCurrentListing.currentFilters &&
    store.getCurrentListing.currentFilters.navigationId
  ) {
    categoryId = store.getCurrentListing.currentFilters.navigationId;
  } else {
    categoryId = null;
  }

  const changePage = (page: any) => {
    getListing({ merge: false, page: page });
  };

  const loadMore = async () => {
    const page = store.getCurrentListing.page;
    await getListing({ page: page + 1, merge: true });
  };

  const search = async (query: any) => {
    listingType = "productSearchListing";
    await getListing({ prop: query });
  };

  const getListing = async ({
    prop = store.getCurrentListing.currentFilters,
    order,
    page = 1,
    merge = false,
    query = null,
    debug = null,
    routeChange = false,
    prefetch = false,
    filter = false,
    isSearch = listingType === "productSearchListing" || route?.query?.query
      ? true
      : false,
  }: GetListingParams) => {
    const currentElements = store.getCurrentListing
      ? store.getCurrentListing.elements
      : null;
    prop = { ...prop };

    prop.filter = [];

    prop.p = page;

    if (order) {
      prop.order = order;
    }

    if (route?.query?.query) {
      prop.query = route.query.query;
    }

    let matchingBrand = null;

    if (prop.brand || brandSlug) {
      if (brandSlug) {
        matchingBrand = store.getBrands.find(
          (item: any) => item.slug === brandSlug
        );
      } else {
        matchingBrand = store.getBrands.find(
          (item: any) => item.id === prop.brand.toString()
        );
      }

      if (matchingBrand) {
        prop.filter.push({
          type: "multi",
          operator: "or",
          queries: [
            {
              type: "equals",
              field: "productBrand.brandId",
              value: matchingBrand.id,
            },
            // {
            //   type: "equals",
            //   field: "productBundle.product.customFields.brandId",
            //   value: matchingBrand.id,
            // },
          ],
        });
      }
      // } else if (prop.filter && prop.associations) {
      //   delete prop.filter;
      //   delete prop.associations;
    }
    const apiPath = isSearch
      ? `${app.$config.public.shopware.shopwareEndpoint}/search?search=${prop.query}`
      : `${app.$config.public.shopware.shopwareEndpoint}/product-listing/${categoryId}`;

    const options: any = {
      method: "POST",
      headers: {
        "sw-access-key": app.$config.public.shopware.shopwareAccessToken,
      },
      body: {
        ...prop,
        ...defaults.useListing,
      },
    };
    if (
      !merge &&
      store.getRestoreListing &&
      store.getRestoreListing.category &&
      store.getRestoreListing.category === categoryId &&
      store.getRestoreListing.path === route.path
    ) {
      store.setCurrentListing(store.getRestoreListing.listing);

      const scrollPos = store.getRestoreListing.scrollPos;

      store.setRestoreListing({
        scrollPos: scrollPos,
        listingRestored: true,
      });
    } else if (
      !merge &&
      !filter &&
      !matchingBrand &&
      (page === "1" || page === 1) &&
      route.fullPath == route.path &&
      store.getPrefetchedRoutes &&
      store.getPrefetchedRoutes.length &&
      store.getPrefetchedRoutes.find((item) => item.category === categoryId)
    ) {
      const prefetchedListing = store.getPrefetchedRoutes.find(
        (item) => item.category === store.getCurrentCategory?.id
      );
      store.setCurrentListing(prefetchedListing);
    } else {
      return await $fetch(apiPath, options)
        .then((res) => {
          let listing: any = res;

          let currentFilters = listing.currentFilters;

          if (prop.order) {
            currentFilters.order = listing.sorting;
          }

          if (listing.page > 1) {
            currentFilters.p = listing.page;
          }

          if (!currentFilters.rating) {
            delete currentFilters.rating;
          }

          if (listing.currentFilters.search) {
            currentFilters.query = listing.currentFilters.search;
            delete currentFilters.search;
          }

          Object.keys(currentFilters).forEach((item) => {
            let filter = currentFilters[item];
            if (
              typeof filter === "object" &&
              Object.keys(filter).find((key) => key === "min" || key === "max")
            ) {
              if (currentFilters[item].min && currentFilters[item].min > 0) {
                currentFilters[`min-${item}`] = currentFilters[item].min;
              }
              if (currentFilters[item].max && currentFilters[item].max > 0) {
                currentFilters[`max-${item}`] = currentFilters[item].max;
              }

              delete currentFilters[item];
            }
          });

          // delete currentFilters.navigationId

          if (prop.brand) {
            currentFilters.brand = prop.brand.toString();
          }

          listing.currentFilters = currentFilters;

          if (merge) {
            listing.elements = [...currentElements, ...listing.elements];
          }

          if (prefetch) {
            store.addPrefetchedRoutes({
              ...listing,
              ...{ category: categoryId },
            });
          } else {
            store.setCurrentListing({
              ...listing,
              ...{ category: categoryId },
            });
          }

          let filtersNoId = currentFilters;

          if (filtersNoId.navigationId) delete filtersNoId.navigationId;

          if (!merge && !routeChange && !prefetch) {
            router.replace({
              query: {
                ...filtersNoId,
              },
            });
          }
          // if (
          //   listing.aggregations.brand &&
          //   listing.aggregations.brand.entities.length === 1 &&
          //   !app.brand
          // ) {
          // } else {
          // }
        })
        .catch((error) => {
          store.setCurrentListing({});
          return;
        });
    }
  };

  const getCurrentPage = computed(() => store.getCurrentListing?.page || 1);

  return {
    changePage,
    loadMore,
    search,
    getListing,
    getCurrentPage,
  };
}
