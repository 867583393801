import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAccount, LazyAds, LazyBrand, LazyErrorPage, LazyFallback, LazyHomepage, LazyLandingPage, LazyMagazine, LazyNavigation, LazyProduct, LazySubbrand } from '#components'
const lazyGlobalComponents = [
  ["Account", LazyAccount],
["Ads", LazyAds],
["Brand", LazyBrand],
["ErrorPage", LazyErrorPage],
["Fallback", LazyFallback],
["Homepage", LazyHomepage],
["LandingPage", LazyLandingPage],
["Magazine", LazyMagazine],
["Navigation", LazyNavigation],
["Product", LazyProduct],
["Subbrand", LazySubbrand],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
