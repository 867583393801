import { defineStore } from "pinia";

export const useStates = defineStore("states", {
  state: () => {
    return {
      cartOpen: false,
      navOpen: false,
      modalOpen: false,
      loginModalOpen: false,
      isLoading: false,
      isSwitchingPages: false,
    };
  },

  // contains your mutations
  actions: {
    setCartOpen(value) {
      this.cartOpen = value;
    },
    setNavOpen(value) {
      this.navOpen = value;
    },
    setModalOpen(value) {
      this.modalOpen = value;
    },
    setLoginModalOpen(value) {
      this.loginModalOpen = value;
    },
    setIsLoading(value) {
      this.isLoading = value;
    },
    setIsSwitchingPages(value) {
      this.isSwitchingPages = value;
    },
  },
  // your root getters
  getters: {
    getCartOpen() {
      return this.cartOpen;
    },
    getNavOpen() {
      return this.navOpen;
    },
    getModalOpen() {
      return this.modalOpen;
    },
    getLoginNavOpen() {
      return this.loginModalOpen;
    },
    getIsLoading() {
      return this.isLoading;
    },
    getIsSwitchingPages() {
      return this.isSwitchingPages;
    },
  },
});
