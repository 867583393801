import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/app/router.options.ts";
const configRouterOptions = {
  scrollBehaviorType: "smooth",
  hashMode: false
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}