import defaults from "~/public/pwa-defaults/api-defaults.json";

export function useCart(mainProduct = {}) {
  const app: any = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();
  const token = useCookie("sw-context-token", {
    expires: new Date(Date.now() + 31536000000),
  });

  const { requestHeaders, requestPath } = utilRequests();

  const count = computed(() => {
    const cart = shopwareStore.cart;
    if (cart?.lineItems?.length) {
      return cart.lineItems.reduce(
        (accumulator, lineItem) =>
          lineItem.type === "product"
            ? lineItem.quantity + accumulator
            : accumulator,
        0
      );
    } else {
      return 0;
    }
  });

  const cart = computed(() => {
    return shopwareStore.getCart;
  });

  const cartItems = computed(() => {
    return cart.value?.lineItems;
  });

  const shippingTotal = computed(() => {
    return cart.value?.deliveries?.[0]?.shippingCosts?.totalPrice;
  });

  const subtotal = computed(() => {
    const cartPrice = cart.value?.price?.positionPrice;
    return cartPrice || 0;
  });
  const cartErrors = computed(
    () => (cart.value?.errors && Object.values(cart.value.errors)) || []
  );

  const appliedPromotionCodes = computed(() => {
    return cartItems.value.filter((cartItem) => cartItem.type === "promotion");
  });

  const totalPrice = computed(() => {
    const cartPrice =
      cart.value && cart.value.price && cart.value.price.totalPrice;
    return cartPrice || 0;
  });

  //Get Cart
  const getCart = async () => {
    return await $fetch(`${requestPath}/checkout/cart`, {
      method: "GET",
      ...requestHeaders.value,
      // body: {
      //   associations: { manufacturer: {} },
      // },
    }).then((response: any) => {
      shopwareStore.setCart(response);
      token.value = response.token;
      return response;
    });
  };

  const refreshCart = async () => {
    getCart();
  };

  const addToCart = async (products) => {
    store.setAllowAddToCart(false);
    const isArray = Array.isArray(products);

    const productArray = isArray ? [...products] : [products];

    const productList = productArray
      ? productArray.map((item) => ({
          id: item.id,
          quantity: item.quantity || 1,
          referencedId: item.id,
          type: item.type || "product",
          label: item.label || "Produktname",
          good: item.good || true,
          removable: item.removable || true,
          stackable: item.stackable || true,
          modified: item.modified || false,
        }))
      : [];

    let cartResponse;

    await $fetch(`${requestPath}/checkout/cart/line-item`, {
      method: "POST",
      ...requestHeaders.value,
      body: { items: productList },
    })
      .then((response: any) => {
        // let err = Object.values(response.errors).filter(
        //   (item) => item.messageKey !== "promotion-discount-added"
        // );
        let err = Object.values(response.errors);

        if (err.length) {
          if (err[0].messageKey === "product-stock-reached") {
            app.$notification.$send("submit", {
              message: `Das Produkt kann nur ${err[0].quantity} mal bestellt werden.`,
              type: "info",
            });
          } else if (err[0].messageKey === "min-order-quantity") {
            app.$notification.$send("submit", {
              message: `Das Produkt muss mindestens ${err[0].quantity} mal bestellt werden.`,
              type: "info",
            });
          } else if (err[0].messageKey === "promotion-not-found") {
            app.$notification.$send("submit", {
              message: `Code konnte nicht hinzugefügt werden.`,
              type: "error",
            });
          } else if (err[0].messageKey.includes("promotion-discount-added")) {
            app.$notification.$send("submit", {
              message: `Code erfolgreich eingelöst`,
              type: "success",
              icon: "sale",
            });
          } else {
            app.$notification.$send("submit", {
              // title: "Produkt hinzugefügt",
              message: `Das Produkt konnte nicht hinzugefügt werden.`,
              type: "error",
            });
          }
        } else {
          productArray.forEach((element) => {
            app.$notification.$send("submit", {
              title: "Produkt hinzugefügt",
              message: `Das Produkt wurde in den Warenkorb gelegt`,
              type: "success",
            });
          });
        }

        cartResponse = response;
        refreshCart();
        store.setAllowAddToCart(true);
      })
      .catch(function (error) {
        console.error(error);
        store.setAllowAddToCart(true);
        app.$notification.$send("submit", {
          title: "Produkt nicht hinzugefügt",
          message: `Es gab leider ein Problem`,
          type: "error",
        });
      });

    return cartResponse;
  };

  const emptyCart = async (refresh = true) => {
    let cartResponse;

    store.setAllowAddToCart(false);

    await $fetch(`${requestPath}/checkout/cart`, {
      method: "DELETE",
      ...requestHeaders.value,
    })
      .then((response) => {
        cartResponse = response;
        store.setAllowAddToCart(true);
        if (refresh) {
          refreshCart();
        }
      })
      .catch(function (error) {
        store.setAllowAddToCart(true);
        console.error(error);
      });
    return cartResponse;
  };

  let removeIds = [];
  let interval;

  const removeItem = (
    product,
    index = 1,
    message = "Das Produkt wurde entfernt"
  ) => {
    let cartResponse;
    store.setAllowAddToCart(false);
    // app.dynamicCartItems.splice(index, 1);

    removeIds.push(product.id);

    clearTimeout(interval);
    interval = setTimeout(() => {
      $fetch(`${requestPath}/checkout/cart/line-item`, {
        method: "DELETE",
        ...requestHeaders.value,
        body: {
          ids: removeIds,
        },
      })
        .then((response) => {
          cartResponse = response;
          store.setAllowAddToCart(true);
          refreshCart();
          removeIds = [];
          app.$bus.$emit("removeFromCart", response);
        })
        .catch(function (error) {
          store.setAllowAddToCart(true);
          console.error(error);
        });
    }, 1500);

    return cartResponse;
  };

  const changeCartItemQuantity = (product, quantity) => {
    let cartResponse;
    $fetch(`${requestPath}/checkout/cart/line-item`, {
      method: "PATCH",
      ...requestHeaders.value,
      body: {
        items: [
          {
            id: product.id,
            quantity: quantity,
          },
        ],
      },
    })
      .then((response) => {
        cartResponse = response;
        store.setAllowAddToCart(true);
        refreshCart();
      })
      .catch(function (error) {
        store.setAllowAddToCart(true);
        console.error(error);
      });

    return cartResponse;
  };

  const addPromotionCode = async (code: String) => {
    const item = {
      type: "promotion",
      id: code,
    };

    await addToCart(item);
  };

  const isInCart = computed(() => {
    return shopwareStore.cart?.lineItems?.find(
      (item) => item.id === mainProduct.id
    )
      ? true
      : false;
  });

  return {
    cart,
    cartItems,
    shippingTotal,
    subtotal,
    cartErrors,
    appliedPromotionCodes,
    totalPrice,
    count,
    refreshCart,
    getCart,
    addToCart,
    emptyCart,
    removeItem,
    changeCartItemQuantity,
    addPromotionCode,
    isInCart,
  };
}
