interface NotificationOptions {
  title?: string | null;
  message?: string | null;
  type?: "success" | "error" | "warning" | "info";
  icon?: string | null;
}

export function useNotification() {
  const app = useNuxtApp(); // Typisierung abhängig von der Nuxt 3 Typdefinition
  const store = useDefaultStore(); // Typisierung abhängig von Ihrem Projekt
  const shopwareStore = useShopwareStore(); // Typisierung abhängig von Ihrem Projekt

  const sendNotification = ({
    title = null,
    message = null,
    type = "success",
    icon = null,
  }: NotificationOptions) => {
    app.$notification.$send("submit", {
      title: title,
      message: message,
      type: type,
      icon: icon,
    });
  };

  return {
    sendNotification,
  };
}
