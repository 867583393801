import { defineStore } from "pinia";

export const useTempStore = defineStore("temp", {
  state: () => {
    return {
      paymentInProcess: false,
      localWishlist: [],
      isHydrating: true,
      showVariantInfo: false,
      variantScollPosition: null,
    };
  },

  // contains your mutations
  actions: {
    setPaymentInProcess(value) {
      this.paymentInProcess = value;
    },
    setLocalWishlist(value) {
      this.localWishlist = value;
    },
    setIsHydrating(value) {
      this.isHydrating = value;
    },
    setShowVariantInfo(value) {
      this.showVariantInfo = value;
    },
    setVariantScollPosition(value) {
      this.variantScollPosition = value;
    },

    // your root getters
  },
  getters: {
    getPaymentInProcess() {
      return this.paymentInProcess;
    },
    getLocalWishlist() {
      return this.localWishlist;
    },
    getIsHydrating() {
      return this.isHydrating;
    },
    getShowVariantInfo() {
      return this.showVariantInfo;
    },
    getVariantScollPosition() {
      return this.variantScollPosition;
    },
  },
});
