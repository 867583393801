export function utilRequests() {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();

  let requestHeaders = computed(() => {
    return {
      headers: {
        "sw-access-key": app.$config.public.shopware.shopwareAccessToken,
        ...(shopwareStore.getContextToken
          ? { "sw-context-token": shopwareStore.getContextToken }
          : {}),
      },
    };
  });

  let requestPath = app.$config.public.shopware.shopwareEndpoint;

  return { requestHeaders, requestPath };
}
