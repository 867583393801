export default defineNuxtPlugin((nuxtApp) => {
  const store = useDefaultStore();

  //Listen to consent manager
  __cmp(
    "addEventListener",
    [
      "consent",
      () => {
        store.setCookieConsent(true);
      },
      false,
    ],
    null
  );

  //Fetch global Strapi Options

  const strapiGlobalOptions = {
    method: "GET",
    progress: false,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  $fetch(
    `${nuxtApp.$config.public.strapi.url}/api/global-option?populate=deep,10`,
    strapiGlobalOptions
  )
    .then((response) => {
      if (response?.data?.attributes?.Active) {
        store.setStrapiGlobalOptions(response.data.attributes);
      }
    })
    .catch((error) => {});

  //get Strapi Global Promotions
  const strapiPromotionOptions = {
    method: "get",
    progress: false,
  };

  $fetch(
    `${nuxtApp.$config.public.strapi.url}/api/global-promotion?populate=deep,10`,
    strapiPromotionOptions
  )
    .then((response) => {
      store.setStrapiGlobalOptions(response.data.attributes);
      if (
        response &&
        response.data &&
        response.data.attributes &&
        response.data.attributes.Active
      ) {
        store.setGlobalPromotion(response.data.attributes);
      }
    })
    .catch((error) => {});
});
