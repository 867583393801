// import {useRouter} from "#vue-router";
//
// export default defineNuxtPlugin(async (nuxtApp) => {
//   const store = useDefaultStore();
//   const router = useRouter();
//
//   //Refresh access token
//   let xPcbAccessToken = localStorage.getItem(
//     nuxtApp.$config.public.sso.ResponseHeaders.ACCESS_TOKEN
//   );
//   let xPcbRefreshToken = localStorage.getItem(
//     nuxtApp.$config.public.sso.ResponseHeaders.REFRESH_TOKEN
//   );
//
//   const { isLoggedIn } = useUser();
//   const { logout, refreshSessionContext } = useShopware();
//
//   const base64UrlDecode = (base64Url) => {
//     let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//     let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
//       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//     }).join(''));
//
//     return JSON.parse(jsonPayload);
//   }
//
//   const getExpirationFromRefreshToken = (refreshToken) =>  {
//     try {
//       const payload = refreshToken.split('.')[1];
//       const decodedPayload = base64UrlDecode(payload);
//       return decodedPayload.exp; // 'exp' claim is in seconds since epoch
//     } catch (error) {
//       console.error("Failed to decode token: ", error);
//       return null;
//     }
//   }
//
//   if (
//     xPcbRefreshToken &&
//     xPcbRefreshToken !== undefined &&
//     xPcbRefreshToken !== "undefined" &&
//     xPcbRefreshToken !== ""
//   ) {
//     // Define Variables
//     let expirationDate =
//         getExpirationFromRefreshToken(xPcbRefreshToken).exp *
//       1000;
//     let expirationTime;
//     let timeoutId;
//
//     const oneHr = 3600 * 1000;
//
//     const tokenExpired = () => {
//       return Date.now() > expirationDate;
//     };
//
//     const invokeLogout = () => {
//       refreshSessionContext().then(() => {
//         logout();
//       });
//     };
//
//     const refreshToken = async () => {
//       await fetch(nuxtApp.$config.public.sso.tokenUrl, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//         body: `grant_type=refresh_token&refresh_token=${localStorage.getItem(
//           nuxtApp.$config.public.sso.ResponseHeaders.REFRESH_TOKEN
//         )}&client_id=${nuxtApp.$config.public.sso.clientId}`,
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           if (
//             data.access_token === undefined &&
//             data.access_token === "undefined" &&
//             data.access_token === ""
//           ) {
//             invokeLogout();
//           } else {
//             localStorage.setItem(
//               nuxtApp.$config.public.sso.ResponseHeaders.ACCESS_TOKEN,
//               data.access_token
//             );
//             localStorage.setItem(
//               nuxtApp.$config.public.sso.ResponseHeaders
//                 .ACCESS_TOKEN_EXPIRES_IN,
//               data.expires_in
//             );
//             localStorage.setItem(
//               nuxtApp.$config.public.sso.ResponseHeaders.REFRESH_TOKEN,
//               data.refresh_token
//             );
//             localStorage.setItem(
//               nuxtApp.$config.public.sso.ResponseHeaders
//                 .REFRESH_TOKEN_EXPIRES_IN,
//               data.refresh_expires_in
//             );
//
//             expirationTime = data.refresh_expires_in;
//             expirationDate =
//                 getExpirationFromRefreshToken(data.refresh_token).exp * 1000;
//           }
//         })
//         .catch((error) => {
//           console.error(error);
//
//           invokeLogout();
//         });
//     };
//
//     refreshToken();
//
//     setInterval(() => {
//       refreshToken();
//     }, (expirationTime / 2.5) * 1000 || oneHr);
//
//     router.beforeEach((to, from, next) => {
//       clearTimeout(timeoutId);
//       if (isLoggedIn.value && tokenExpired()) {
//         refreshToken();
//       }
//       return next();
//     });
//
//     router.afterEach(() => {
//       timeoutId = setTimeout(() => {
//         if (isLoggedIn.value && tokenExpired()) {
//           refreshToken();
//         }
//       }, expirationTime * 1000 || oneHr);
//     });
//   } else {
//   }
// });
