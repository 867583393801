export type Route = {
  path: string;
  query: { slug: string };
};

export function useStrapi({ route }: { route: Route }) {
  const app = useNuxtApp();
  const store = useDefaultStore();

  const getStrapiPage = async () => {
    const pageType: string = getPageType(route.path);
    const isPreview: boolean = route.path.startsWith(`/api/preview`);
    let apiPath: string | undefined;
    let seoPath: string = "seo-sections";

    // Define apiPath using a switch-case
    if (pageType === "magazine") {
      apiPath = "magazines";
    } else if (pageType === "lanfingpage") {
      apiPath = "landingpages";
    } else if (pageType === "ads") {
      apiPath = "ads";
    } else if (pageType === "product") {
      seoPath = "seo-section-products";
    } else {
      apiPath = "pages";
    }

    const url: string = isPreview
      ? `${
          app.$config.public.strapi.url
        }/api/${apiPath}?publicationState=preview&filters[Path][$eqi]=${encodeURIComponent(
          route.query.slug
        )}&populate=deep,10`
      : `${
          app.$config.public.strapi.url
        }/api/${apiPath}?filters[Path][$eqi]=${encodeURIComponent(
          route.path
        )}&populate=deep,10`;

    if (apiPath) {
      await $fetch(url)
        .then((response: any) => {
          if (!response.data || !response.data.length) {
            store.setStrapiContent(null);
          } else {
            store.setStrapiContent(response.data);
          }
        })
        .catch((error: any) => {
          store.setStrapiContent(null);
        });
    } else {
      store.setStrapiContent(null);
    }

    //Fetch SEO stuff
    const seoUrl: string = `${
      app.$config.public.strapi.url
    }/api/${seoPath}?filters[Path][$eqi]=${encodeURIComponent(
      route.path
    )}&populate=deep,10`;

    await $fetch(seoUrl)
      .then((seoResponse: any) => {
        if (seoResponse.data.length) {
          store.setStrapiSeo(seoResponse.data[0].attributes);
        } else {
          store.setStrapiSeo(null);
        }
      })
      .catch((error: any) => {
        store.setStrapiSeo(null);
      });
  };

  return { getStrapiPage };
}
