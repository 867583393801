import revive_payload_client_pU1tHFmhAJ from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9s3FmAbk9m from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_og2RyQrdTf from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nXuQ6TWlgF from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Zck3cQXnTq from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_sWFlLGWUTf from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1pdgHMD1aU from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_jvLAzt1Lw4 from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.1_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_CN5jsDY6km from "/app/node_modules/.pnpm/nuxt@3.13.0_rollup@4.21.1_sass@1.77.8_typescript@5.5.4_vite@5.4.2_vue-tsc@2.0.29/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_slZLFOE1iw from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.0_rollup@4.21.1_vue@3.4.38/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_woYczrSxkj from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.21.1_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0LLp5O6Dz1 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_rollup@4.21.1_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sentry_client_7L9zFHWZ6y from "/app/node_modules/.pnpm/@sentry+nuxt@8.27.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.25.1_@opentelemetry+instru_fp4de7bu6ge5aj6ai444jpcgba/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/app/.nuxt/sentry-client-config.mjs";
import bodyScrollLockDirective_PFVtPXiIP3 from "/app/plugins/bodyScrollLockDirective.ts";
import checkContextToken_client_KF1IOlmD0X from "/app/plugins/checkContextToken.client.js";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import initApp_client_HB3je16GJC from "/app/plugins/initApp.client.ts";
import intersectionObserverDirective_hyi5PUXUBF from "/app/plugins/intersectionObserverDirective.ts";
import lottie_player_client_G22svxIhbR from "/app/plugins/lottie-player.client.ts";
import misc_client_XKFAtM619D from "/app/plugins/misc.client.ts";
import mitt_client_7uatExallr from "/app/plugins/mitt.client.ts";
import prefetchRoutes_client_suwW2YaEjO from "/app/plugins/prefetchRoutes.client.ts";
import scrollDirection_client_oUg7iS9aUv from "/app/plugins/scrollDirection.client.ts";
import settingDefaults_client_hSwNPT1S0t from "/app/plugins/settingDefaults.client.js";
export default [
  revive_payload_client_pU1tHFmhAJ,
  unhead_9s3FmAbk9m,
  router_og2RyQrdTf,
  payload_client_nXuQ6TWlgF,
  navigation_repaint_client_Zck3cQXnTq,
  check_outdated_build_client_sWFlLGWUTf,
  chunk_reload_client_1pdgHMD1aU,
  plugin_vue3_jvLAzt1Lw4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CN5jsDY6km,
  plugin_slZLFOE1iw,
  switch_locale_path_ssr_woYczrSxkj,
  i18n_0LLp5O6Dz1,
  sentry_client_7L9zFHWZ6y,
  sentry_client_config_o34nk2sJbg,
  bodyScrollLockDirective_PFVtPXiIP3,
  checkContextToken_client_KF1IOlmD0X,
  clickOutsideDirective_JuSoe6vGeC,
  initApp_client_HB3je16GJC,
  intersectionObserverDirective_hyi5PUXUBF,
  lottie_player_client_G22svxIhbR,
  misc_client_XKFAtM619D,
  mitt_client_7uatExallr,
  prefetchRoutes_client_suwW2YaEjO,
  scrollDirection_client_oUg7iS9aUv,
  settingDefaults_client_hSwNPT1S0t
]