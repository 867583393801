//writing default setting values in localStorage
export default defineNuxtPlugin((nuxtApp) => {
  const tempStore = useTempStore();

  const defaultSettings = {
    settingList: [
      {
        type: "infiniteScroll",
        name: "Infinite Scrolling",
        description:
          "Aktiviere oder deaktiviere infinite Scrolling in Produktlisten",
        default: true,
      },
      {
        type: "largeText",
        name: "Großer Text",
        description: "Passe die Schriftgröße an",
        default: false,
      },
    ],
    settingsVersion: 2,
  };

  if (
    window.localStorage.settings === (null || undefined) ||
    window.localStorage.settings.includes("[object Object]") ||
    !JSON.parse(window.localStorage.getItem("settings")).settingsVersion ||
    JSON.parse(window.localStorage.getItem("settings")).settingsVersion !==
      defaultSettings.settingsVersion
  ) {
    window.localStorage.setItem("settings", JSON.stringify(defaultSettings));
  }
});
