import { defineStore } from "pinia";

export const useShopwareStore = defineStore("shopware", {
  state: () => {
    return {
      contextToken: null,
      context: null,
      user: null,
      countries: null,
      languages: null,
      salutations: null,
      currencies: null,
      addresses: null,
      cart: {},
      wishlist: {},
      shippingMethods: null,
      paymentMethods: null,
    };
  },

  // contains your mutations
  actions: {
    setContextToken(value) {
      this.contextToken = value;
    },
    setContext(value) {
      this.context = value;
    },
    setUser(value) {
      this.user = value;
    },
    setCountries(value) {
      this.countries = value;
    },
    setLanguages(value) {
      this.languages = value;
    },
    setSalutations(value) {
      this.salutations = value;
    },
    setCurrencies(value) {
      this.currencies = value;
    },
    setAddresses(value) {
      this.addresses = value;
    },
    setCart(value) {
      this.cart = value;
    },
    setWishlist(value) {
      this.wishlist = value;
    },
    setShippingMethods(value) {
      this.shippingMethods = value;
    },
    setPaymentMethods(value) {
      this.paymentMethods = value;
    },
  },
  // your root getters
  getters: {
    getContextToken() {
      return this.contextToken;
    },
    getContext() {
      return this.context;
    },
    getUser() {
      return this.user;
    },
    getCart() {
      return this.cart;
    },
    getWishlist() {
      return this.wishlist;
    },
    getCountries() {
      return this.countries;
    },
    getLanguages() {
      return this.languages;
    },
    getSalutations() {
      return this.salutations;
    },
    getCurrencies() {
      return this.currencies;
    },
    getAddresses() {
      return this.addresses;
    },
    getShippingMethods() {
      return this.shippingMethods;
    },
    getPaymentMethods() {
      return this.paymentMethods;
    },
  },
});
