import mitt from "mitt";
const emitter = mitt();
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide("bus", {
    $on: emitter.on,
    $off: emitter.off,
    $emit: emitter.emit,
  });
  nuxtApp.provide("notification", {
    $get: emitter.on,
    $send: emitter.emit,
  });
});
