import { effectScope } from "vue-demi";
// import {
//   useUser,
//   extendScopeContext,
//   getApplicationContext,
// } from "../composables/pcComposables";
import { PAGE_LOGIN } from "../utils/pages";
import { Context } from "@nuxt/types";

/**
 * 1. Check if requesting route is restricted only for authenticated user
 * 2. Redirect to /login otherwise (always force logout on /login route)
 */
export default defineNuxtRouteMiddleware(async (to: Route, from: Route) => {
  const { isLoggedIn, isGuestSession } = useUser();
  const { logout } = useShopware();

  if (to.path.includes("/account/recover/password")) return;

  if (
    to.path.startsWith("/account") &&
    (!isLoggedIn.value || isGuestSession.value)
  ) {
    return navigateTo("/login");
  }
});
