export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("observe", {
    mounted(el, binding) {
      let visibleOnce = false;
      let onlyEnter = false;
      let onlyLeave = false;

      const intersectionObserver = new IntersectionObserver(
        (entry, observer) => {
          const item = entry[0];

          if (item.isIntersecting) {
            //Make sure, that leave event is not fired until element entered viewport once
            visibleOnce = true;

            if (!onlyLeave) {
              //Events when Element enters viewport
              item.target.classList.add("item-observe-active");
              item.target.dispatchEvent(new Event("elementEntered"));
              // observer.unobserve(entry.target)
            }
          } else {
            if (visibleOnce && !onlyEnter) {
              //Events when Element leaves viewport
              item.target.classList.remove("item-observe-active");
              item.target.dispatchEvent(new Event("elementLeft"));
            }
          }
        },
        {
          threshold: 0.01,
          rootMargin: "400px",
        }
      );

      if (!binding.hasOwnProperty("value") || binding.value !== false) {
        el.classList.add("item-observe");
        intersectionObserver.observe(el);

        // console.dir(el.offsetTop)
        // console.dir(window.innerHeight)

        if (el.offsetTop < window.innerHeight) {
          el.classList.add("item-observe-active");
        }

        //Allowed arguments: true, false, onlyEnter and onlyLeave

        if (binding.value && binding.value === "onlyEnter") {
          onlyEnter = true;
        }
        if (binding.value && binding.value === "onlyLeave") {
          onlyLeave = true;
        }
      }
    },
    inserted: (el) => {},
    update: (el) => {},
    unmounted(el) {},
  });
});
