export function useUser() {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();

  const { requestHeaders, requestPath } = utilRequests();

  //Get User
  const user = computed(() => shopwareStore.getUser);

  // User Status
  const isLoggedIn = computed(() => shopwareStore.getUser?.active);
  const isGuestSession = computed(() => shopwareStore.getUser?.guest);

  // Addresses
  const activeShippingAddress = computed(
    () =>
      shopwareStore.getUser?.activeShippingAddress ||
      shopwareStore.getUser?.defaultShippingAddress
  );
  const activeBillingAddress = computed(
    () =>
      shopwareStore.getUser?.activeBillingAddress ||
      shopwareStore.getUser?.defaultBillingAddress
  );

  const addresses = computed(() => shopwareStore.getAddresses);

  //Shipping Methods
  const shippingMethods = computed(() => shopwareStore.getShippingMethods);
  const activeShippingMethod = computed(
    () => shopwareStore.getContext?.shippingMethod
  );

  //Payment Methods
  const paymentMethods = computed(() => shopwareStore.getPaymentMethods);
  const activePaymentMethod = computed(
    () => shopwareStore.getContext?.paymentMethod
  );

  return {
    user,
    isLoggedIn,
    isGuestSession,
    activeShippingAddress,
    activeBillingAddress,
    addresses,
    shippingMethods,
    paymentMethods,
    activeShippingMethod,
    activePaymentMethod,
  };
}
