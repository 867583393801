export function usePrefetchListing() {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();

  const prefetchListing = (id: string) => {
    const { getListing } = useListing({ listingId: id });

    if (
      store.getPrefetchedRoutes.length &&
      store.getPrefetchedRoutes.find((cat) => cat.category === id)
    ) {
      return;
    }

    setTimeout(() => {
      getListing({ prop: null, prefetch: true });
    }, 1000);
  };

  return {
    prefetchListing,
  };
}
