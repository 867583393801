//get Scroll direction

export default defineNuxtPlugin((nuxtApp) => {
  if (window != undefined) {
    let isScrolling;
    let scrollHeight = 0;

    const bodyEl = document.querySelector("body");

    window.addEventListener("scroll", () => {
      window.clearTimeout(isScrolling);

      if (window.scrollY > scrollHeight + 150) {
        bodyEl.setAttribute("data-scroll-direction", "down");
      } else if (window.scrollY === 0) {
        bodyEl.setAttribute("data-scroll-direction", "top");
      } else if (window.scrollY < scrollHeight - 50) {
        bodyEl.setAttribute("data-scroll-direction", "up");
      }
      isScrolling = setTimeout(function () {
        scrollHeight = window.scrollY;
      }, 100);
    });
  }
});
