interface Category {
  id: string;
  children?: Category[];
  translated?: { name: string };
  extensions?: { animalSpecies?: string[] };
  parentId?: string;
}

export function useCategory() {
  const app = useNuxtApp();
  const store = useDefaultStore();

  const getCategoryObject = (id: string): Category | undefined => {
    const navElements: Category[] = store.navElements;
    let categories: Category[] = [];

    const findCategories = (arr: Category[]) => {
      arr.forEach((arr) => {
        categories.push(arr);
        if (arr.children && arr.children.length) {
          findCategories(arr.children);
        }
      });
    };

    findCategories(navElements);

    let result = categories.find((item) => item.id === id);

    return result;
  };

  const getCategoryName = (id: string): string | null => {
    const cat = getCategoryObject(id);
    return cat && cat.translated ? cat.translated.name : null;
  };

  const getActiveAnimalSpecies = (currentCategory: Category): void => {
    //Helper function to find parent in category tree
    const findParent = (id: string): Category | undefined => {
      return store.flatCategories.find((item) => item.id === id);
    };

    //Check current category and find matching animal species to set as active animal species in store
    const checkForSpecies = (category: Category): void => {
      if (
        //Check if current category has animal species
        category &&
        category.extensions &&
        category.extensions.animalSpecies &&
        category.extensions.animalSpecies.length
      ) {
        store.setActiveAnimalSpecies(category.extensions.animalSpecies[0]);
      } else if (category?.parentId) {
        //Check if parent has animal species
        const parentCategory = findParent(category?.parentId);

        if (parentCategory) {
          checkForSpecies(parentCategory);
        } else {
          store.setActiveAnimalSpecies(null);
        }
      } else {
        store.setActiveAnimalSpecies(null);
      }
    };

    checkForSpecies(currentCategory);
  };

  return {
    getCategoryObject,
    getCategoryName,
    getActiveAnimalSpecies,
  };
}
