export function getPageType(path) {
  const store = useDefaultStore();
  const isPreview = path.startsWith(`/api/preview`) ? true : false;
  const app = useNuxtApp();

  let pageType = "fallback";
  if (path == "/") {
    pageType = "homepage";
  } else if (
    path.startsWith("/" + app.$i18n.t("path.magazine")) ||
    (isPreview && to.query.type === "magazines")
  ) {
    pageType = "magazine";
  } else if (path.startsWith("/" + app.$i18n.t("path.brands") + "/")) {
    const pathParams = path.slice(1).split("/");
    if (pathParams.length < 3) {
      pageType = "brand";
    } else {
      pageType = "subbrand";
    }
  } else if (
    path.startsWith("/" + app.$i18n.t("path.landingpage")) ||
    (isPreview && to.query.type === "landingpages")
  ) {
    pageType = "landingpage";
  } else if (path.startsWith("/" + app.$i18n.t("path.ads"))) {
    pageType = "ads";
  } else if (
    (path.startsWith("/p/") || path.startsWith("/detail/")) &&
    store.getCurrentProduct &&
    store.getCurrentProduct.id
  ) {
    pageType = "product";
  } else if (path.startsWith("/login") || path.startsWith("/account")) {
    pageType = "account";
  } else if (
    store.getCurrentListing &&
    Object.keys(store.getCurrentListing).length
  ) {
    pageType = "navigation";
  } else {
    pageType = "fallback";
  }

  return pageType;
}
