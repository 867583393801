import defaults from "~/public/pwa-defaults/api-defaults.json";

export function getProduct({ path }: { path: any }) {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();
  const { requestHeaders, requestPath } = utilRequests();
  const { getActiveAnimalSpecies } = useCategory();
  const router = useRouter();

  const fetchProduct = async () => {
    const pathMatch = path.path.substring(1);

    const productOptions = {
      method: "POST",
      ...requestHeaders.value,
      body: {
        ...defaults.useCms,
        path: pathMatch,
      },
    };

    return await $fetch(`${requestPath}/pwa/page`, productOptions)
      .then(async (response: any) => {
        await store.setPage(response);
        await store.setCurrentProduct(response.product);

        // Check if you can set an acticve category
        const findMatchingCategory = () => {
          if (store.flatCategories.length) {
            const categories = JSON.parse(JSON.stringify(store.flatCategories));
            let findCategory = categories.find(
              (item: any) =>
                item.id ===
                response.product.categoryIds[
                  response.product.categoryIds.length - 1
                ]
            );
            if (findCategory) {
              return findCategory;
            } else {
            }
          }
        };
        await store.setCurrentCategory(findMatchingCategory());

        // Check if you can set an acticve animal species
        getActiveAnimalSpecies(findMatchingCategory());

        // if (
        //   pathMatch &&
        //   response?.canonicalPathInfo &&
        //   pathMatch !== response?.canonicalPathInfo
        // ) {
        //   return navigateTo(response.canonicalPathInfo);
        // }
      })
      .catch((error: any) => {});
  };

  return { fetchProduct };
}

export function useProduct(product: any) {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();
  const { requestHeaders, requestPath } = utilRequests();
  const { getActiveAnimalSpecies } = useCategory();
  const router = useRouter();

  const parentProductId = computed(() => product.parentId);

  const availaibleOptions = computed(() => {
    if (store.getPage?.configurator?.length) {
      return store.getPage?.configurator;
    } else {
      return null;
    }
  });

  const findVariantForSelectedOptions = async (options) => {
    const filter = [
      {
        type: "equals",
        field: "parentId",
        value: parentProductId.value,
      },
      ...Object.values(options).map((id) => ({
        type: "equals",
        field: "optionIds",
        value: id,
      })),
    ];

    return await $fetch(`${requestPath}/product`, {
      method: "POST",
      ...requestHeaders.value,
      body: {
        limit: 1,
        filter,
        includes: {
          product: ["id", "translated", "productNumber", "seoUrls"],
          seo_url: ["seoPathInfo"],
        },
        associations: {
          seoUrls: {},
        },
      },
    }).then((response) => {
      return response?.elements?.[0];
    });
  };

  return { findVariantForSelectedOptions, availaibleOptions };
}
