export function useRequest() {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();
  const { requestHeaders, requestPath } = utilRequests();

  const swRequest = (path: string, body: object, method: any = "POST") => {
    return $fetch(`${requestPath}${path}`, {
      method: method,
      ...requestHeaders.value,
      body: body,
    });
  };

  const strapiRequest = (path: string) => {
    return $fetch(`${app.$config.public.strapi.url}/api${path}`, {
      method: "GET",
    }).catch((error: any) => {});
  };

  return { swRequest, strapiRequest };
}
