export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("click-outside", {
    mounted(el, binding) {
      el.clickOutsideEvent = function (event) {
        // Check if the clicked element is neither the element
        // to which the directive is applied nor its child
        if (!(el === event.target || el.contains(event.target))) {
          // Invoke the provided method
          binding.value(event);
        }
      };

      if (!binding.hasOwnProperty("value") || binding.value !== false) {
        document.addEventListener("mouseup", el.clickOutsideEvent);
        document.addEventListener("touchend", el.clickOutsideEvent);
      }
    },
    updated(el) {},
    unmounted(el) {
      // Remove the event listener when the bound element is unmounted
      document.removeEventListener("mouseup", el.clickOutsideEvent);
      document.removeEventListener("touchend", el.clickOutsideEvent);
    },
  });
});
